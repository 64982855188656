import React from 'react'
import Layout from "../components/Layout";
import AnimatedText from "../components/AnimatedText";
import profilePic from "../assets/developer-pic-1.png"
import lightBulb from "../assets/miscellaneous_icons_1.svg";
import HireMe from '../components/HireMe';
import TransitionEffect from '../components/TransitionEffect';
import { Link } from 'react-router-dom';
const Home = () => {
return (
<>
  <TransitionEffect />
    <div className='flex items-center text-dark w-full h-screen dark:text-light  md:w-full md:min-h-screen'>
    <Layout className='pt-0 md:pt-16 sm:pt-8'>
      <div className='flex items-center justify-between w-full lg:flex-col '>
        <div className='w-1/2 md:w-full'>
          <img src={profilePic} alt="D&D webdesign"
            className='w-full h-auto  md:inline-block md:w-full drop-shadow-[-20px_-7px_9px_rgba(0,0,0,0.3)] dark:drop-shadow-[-20px_-7px_9px_#0ef]' />
        </div>
        <div className='w-1/2 flex flex-col items-center self-center lg:w-full lg:text-center'>
          <AnimatedText text="A webdesign legmodernebb módja."
            className='!text-6xl !text-left xl:!text-5xl lg:!text-center lg:!text-6xl md:!text-5xl sm:!text-2xl xs:!text-lg' />
          <p className='my-4 text-base font-medium md:text-sm sm:text-xs'>Fedezze fel a webdesign új korszakát velünk.
            Innovatív, egyedi, Önért.Legyen részese az innovációnak.
          </p>
          <div className='flex items-center self-start mt-2 lg:self-center'>
            <Link to={'/about'}> <button
              className="middle none center rounded-lg bg-primaryDark dark:bg-primaryDark py-3 px-6 font-sans text-xs font-bold uppercase text-white dark:text-dark shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40  dark:shadow-blue-400 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              data-ripple-light="true">
            Érdekel
              </button></Link>
              <Link to={'/contact'} className='ml-2'> <button
              className="middle none center rounded-lg bg-primaryDark dark:bg-primaryDark py-3 px-6 font-sans text-xs font-bold uppercase text-white  dark:text-dark shadow-md shadow-pink-500/20 dark:shadow-blue-400 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              data-ripple-light="true">
            Kapcsolat
            </button></Link>
          </div>
        </div>
      </div>
    </Layout>
    <HireMe />
    <div className='absolute right-8 bottom-8 inline-block w-24 md:hidden'>
      <img src={lightBulb} alt='D&D webdesign izzó' className='w-full h-auto' />
    </div>
  </div>
</>
)
}

export default Home