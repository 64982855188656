import React from 'react'
import { Link, parsePath } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {useState} from 'react'
import {motion} from 'framer-motion'
import useThemeSwitcher from './hooks/useThemeSwitcher'
import { SunIcon } from './Icons'
import { MoonIcon } from './Icons'
import { BsSunFill } from "react-icons/bs";
import { BsFillMoonFill } from "react-icons/bs"
import { BsFacebook,BsInstagram } from 'react-icons/bs';
import Logo from './Logo';

const CustomLink = ({ href, title, className = "" }) => {
return (

<Link to={href} className={`${className} relative group`}>
{title}
<span className={`h-[1px] inline-block w-0 bg-dark absolute left-0 -bottom-0.5 group-hover:w-full transition-[width]
    ease duration-300${parsePath===href ? 'w-full' : 'w-0' } dark:bg-light`}>&nbsp;</span>
</Link>
)
}

const CustomMobilLink = ({ href, title, className = "", toggle }) => {
const navigate = useNavigate();
const hadleClick = () => {
toggle();
navigate(href)
}
return (
<button href={href} className={`${className} relative group text-light dark:text-dark my-2 `} onClick={hadleClick}>
    {title}
    <span className={`h-[1px] inline-block w-0 bg-light dark:bg-dark absolute left-0 -bottom-0.5 group-hover:w-full
        transition-[width] ease duration-300${parsePath===href ? 'w-full' : 'w-0' } dark:bg-light`}>&nbsp;</span>
</button>
)
}
const Navbar = () => {
const [mode, setMode] = useThemeSwitcher();
const [isOpen, setIsOpen] = useState(false);
const handleClick = () => {
setIsOpen(!isOpen)

}
return (
<header
    className='w-full px-32 py-8 font-medium flex items-center justify-between dark:text-light relative z-10 lg:px-16 md:px-12 sm:px-8 bg-light dark:bg-dark'>
    <button className=' flex-col justify-center items-center hidden lg:flex' onClick={handleClick}>
        <span className={`bg-dark dark:bg-light block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm
            ${isOpen?'rotate-45 translate-y-1':'-translate-y-0.5'}`}></span>
        <span className={`bg-dark dark:bg-light block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm my-0.5
            ${isOpen?'opacity-0':'opacity-100'}`}></span>
        <span className={`bg-dark dark:bg-light block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm
            ${isOpen?'-rotate-45 -translate-y-1':'translate-y-0.5'}`}></span>
    </button>

    {/*MobileMenu*/
    isOpen ?
    <motion.div initial={{ scale: 0,opacity:0,x:"-50%",y:"-50%" }} animate={{scale:1,opacity:1}} className='min-w-[70vw] flex flex-col justify-between items-center fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-30
              bg-dark/90 dark:bg-light/75 rounded-lg backdrop-blur-md py-32
              '>
        <nav className='flex items-center flex-col justify-center'>
            <CustomMobilLink href='/' title="Home" className='' toggle={handleClick} />
            <CustomMobilLink href='/about' title="Rólam" className='' toggle={handleClick} />
            <CustomMobilLink href='/projects' title="Projectek" className='' toggle={handleClick} />
            <CustomMobilLink href='/contact' title="Contact" className='' toggle={handleClick} />
        </nav>
        <nav className='flex items-center justify-center flex-wrap mt-2'>
            <motion.a href="https://www.linkedin.com/" target='_blank' whileHover={{y:-3}} whileTap={{scale:0.9}}
                className='w-6 mr-3 sm:mx-1'>
                <BsInstagram size={25} />
            </motion.a>
            <motion.a href="https://www.facebook.com/profile.php?id=61552477017691" target='_blank' whileHover={{y:-3}} whileTap={{scale:0.9}}
                className='w-6 mr-1.5 sm:mx-1 text-blue-600 '>
                <BsFacebook size={25} />
            </motion.a>
            <button onClick={()=> {
                setMode(mode === "light" ? "dark" : "light")
                }} className={`ml-3 flex items-center justify-center rounded-full p-1
                ${mode==="light"?"bg-dark text-light" : "bg-light text-dark"}`}>
                {
                mode === "dark" ?
                <BsSunFill className={"fill-dark"} />
                :
                <BsFillMoonFill className={"fill-light"} />
                }
            </button>
        </nav>
    </motion.div>
    : null
    }

    <div className='w-full flex justify-between items-center lg:hidden'>

        <nav>
            <CustomLink href='/' title="Home" className='mr-4' />
            <CustomLink href='/about' title="Rólam" className='mx-4' />
            <CustomLink href='/projects' title="Projectek" className='mx-4' />
            <CustomLink href='/contact' title="Contact" className='mx-4' />

        </nav>
        <nav className='flex items-center justify-center flex-wrap'>
            <motion.a href="https://www.linkedin.com/" target='_blank' whileHover={{y:-3}} whileTap={{scale:0.9}}
                className='w-6 mr-3 sm:mx-1'>
                    <BsInstagram size={25} />
            </motion.a>
            <motion.a href="https://www.facebook.com/profile.php?id=61552477017691" target='_blank' whileHover={{y:-3}} whileTap={{scale:0.9}}
                className='w-6 mr-1.5 sm:mx-1 text-blue-600 '>
                <BsFacebook size={25} />
            </motion.a>
            <button onClick={()=> {
                setMode(mode === "light" ? "dark" : "light")
                }} className={`ml-1 flex items-center justify-center rounded-full p-1
                ${mode==="light"?"bg-dark text-light" : "bg-light text-dark"}`}>
                {
                mode === "dark" ?
                <SunIcon className={"fill-dark sm:!w-7 sm:!h-7"} />
                :
                <MoonIcon className={"fill-dark sm:!w-7 sm:!h-7"} />
                }
            </button>
        </nav>
    </div>
    <div className='absolute left-[50%] top-2 translate-x-[-50%] '>
                <Logo/>
            </div>
</header>
    
)
}

export default Navbar