import React from "react";
import Custom from "../assets/egyediweboldal.png";
import Responsive from "../assets/responsive-design 1.png";
import Redesign from "../assets/redesign.png";
import Domain from "../assets/domain 1.png";
import Google from "../assets/google.png";
import Graphics from "../assets/ux 1.png";
import SEO from "../assets/seo.png";
import Database from "../assets/data-integration 1.png";
import Ecommerce from "../assets/online-shop 1.png";
import CMS from "../assets/cms.png";
import Social from "../assets/social-media 1.png"
const Services = () => {
  const techs = [
    {
        id: 1,
        src: Custom,
        title: "Egyedi weboldal tervezés és fejlesztés",
        style: "shadow-gray-400",
      },
      {
        id: 2,
        src: Responsive,
        title: "Responsive webdesign",
        style: "shadow-yellow-500",
      },
    {
      id: 3,
      src: Redesign,
      title: "Weboldal újratervezés",
      style: "shadow-purple-500",
    },
    {
      id: 4,
      src: Domain,
      title: "Domain és web hosting segítség",
      style: "shadow-blue-500",
    },
    {
      id: 5,
      src: Google,
      title: "Google Business Profile beállítás",
      style: "shadow-orange-400",
    },
    {
      id: 6,
      src: Graphics,
      title: "Grafikai tervezés logótervezés",
      style: "shadow-yellow-400",
    },
    {
      id: 7,
      src:SEO,
      title: "On-page és Off Page SEO",
      style: "shadow-blue-400",
    },
    {
      id: 8,
      src:Database,
      title: "Adatbázis Integráció",
      style: "shadow-red-400",
      },
      {
        id: 9,
        src:Ecommerce,
        title: "E-kereskedelmi funkciók",
        style: "shadow-orange-700",
      },
      {
        id: 10,
        src:CMS,
        title: "Cms telepítés,beállítás",
        style: "shadow-green-400",
      },
      {
        id: 11,
        src:Social,
        title: "Social Média tevékenységek",
        style: "shadow-blue-400",
      },
  ];

  return (
    <div
      name="services"
      className='w-full h-screen relative rounded-full flex items-center justify-center bg-light dark:bg-dark my-60 sm:my-[800px]'
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div className="flex flex-col items-center justify-center">
          <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline text-dark dark:text-light">
            Szolgáltatásaink
          </p>
          <p className="py-6 text-dark dark:text-light xs:hidden">Fedezze fel szolgáltatásainkat: Az Ön igényeihez szabva</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-1 gap-8 text-center py-8 px-12 sm:px-0  ">
          {techs.map(({ id, src, title, style }) => (
            <div
              key={id}
              className={`shadow-lg hover:scale-105 duration-500 py-2 rounded-lg ${style} bg-dark`}
            >
              <img src={src} alt="" className="w-20 mx-auto" />
              <p className="mt-4">{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;