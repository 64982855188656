import React from 'react'
import Layout from './Layout'
import { Link } from 'react-router-dom'
const Footer = () => {
return (
<footer
  className=' w-full border-t-2 border-solid border-dark font-medium text-lg dark:text-light dark:border-light sm:text-base'>
  <Layout className='py-8 flex items-center justify-between lg:flex-col lg:py-6'>
    <span>{new Date().getFullYear()}&copy; D<small>&</small>D design | All Rights Reserved</span>
    <div className='flex items-center lg:py-2'>
      Build With <span className='text-primary text-2xl px-1'>&hearts;</span>
      by &nbsp;
      <Link to="https://www.webdesign-dd.hu" className='underline underline-offset-2'>D&D</Link>
    </div>
    <div className='flex'>
      <Link to="/about"
        className='underline underline-offset-2 text-primary dark:text-primaryDark'>Rólam</Link>
      <Link to="/gdpr"
        className='underline underline-offset-2 ml-3 text-primary dark:text-primaryDark' target=''>GDPR</Link>
      <Link to="/impressum"
        className='underline underline-offset-2 ml-3 text-primary dark:text-primaryDark' target=''>Impressum</Link>
      <Link to="/contact"
        className='underline underline-offset-2 ml-2 text-primary dark:text-primaryDark' target=''>Contact</Link>
    </div>
  </Layout>
</footer>
)
}

export default Footer