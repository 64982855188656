import React from 'react'
import Layout from '../components/Layout';
import AnimatedText from "../components/AnimatedText";

const PrivacyPolicy = () => {
return (
<>
    <Layout>
        <div className='w-full h-auto text-center'>
            <AnimatedText text="Adatvédelmi Tájékoztató"
                className='!text-6xl  xl:!text-5xl lg:!text-center lg:!text-6xl md:!text-5xl sm:!text-3xl !text-center xs:!text-base' />
            <div className='w-full flex flex-col items-center self-center lg:w-full lg:text-center'>
                <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                    amely készült a Dovigyel László egyéni vállalkozó által üzemeltetett alábbi domain(eke)t látogató
                    Érintettek részére: https://www.webdesign-dd.hu
                    <br />
                    Jelen Adatkezelési tájékoztató hatálya kiterjed a fenti domainek alatt üzemelő valamennyi
                    aldomainre.

                    Dovigyel László egyéni vállalkozó adatkezelőként a működése során a vele kapcsolatban lévő
                    természetes személyek, valamint azon természetes személyek, akiknek adatai üzleti tevékenysége
                    kapcsán a birtokába jutnak (a továbbiakban valamennyien együtt: Érintettek) adatainak védelmét és
                    biztonságát, adataihoz fűződő jogainak tiszteletben tartását, érvényesítésének lehetőségét rendkívül
                    fontosnak tartja. Erre figyelemmel a hatályos magyar és európai jogi rendelkezések betartása
                    céljából és érdekében az alábbi rövid tájékoztatást nyújtja mindazon Érintettek részére, akik a
                    fenti domain nevek alatt található információk alapján kívánja vele felvenni a kapcsolatot, avagy a
                    fenti domain nevek alatt bármely szolgáltatást igénybe veszi.
                </p>
                <div className='w-full flex flex-col align-baseline'>
                    <h1
                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        1. Ki az Adatkezelő?</h1>
                </div>
                <div className='w-full'>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                            Az Adatkezelő adatai:</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Adószám:</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        E-mail cím: dovigyel.l@webdesign-dd.hu</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Székhely: 9226 Dunasziget Sérfenyő utca 50.</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Az adatvédelemért felelős személy megegyezik az egyéni vállalkozóval/adatkezelővel: Dovigyel
                        László, akinek elérhetőségeit az előző pont tartalmazza.</p>
                </div>
                <div className='w-full flex flex-col align-baseline'>
                    <h1
                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        2. Kik az Érintettek?</h1>
                </div>
                <div className='w-full'>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Érintett minden, bármely meghatározott, személyes adat alapján azonosított vagy – akár közvetve
                        is – azonosítható természetes személy, akinek adatait az Adatkezelő kezeli.

                        Érintett lehetsz akkor, ha az Adatkezelő által üzemeltetett bármely fenti domain alatt működő
                        weboldalt meglátogatod, azon szolgáltatást veszel igénybe.</p>

                </div>
                <div className='w-full flex flex-col align-baseline'>
                    <h1
                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        3. Miért, milyen jogalappal és mennyi ideig kezelem az adataidat?</h1>
                </div>
                <div className='w-full'>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Ahhoz, hogy sikeresen véghez tudjam vinni a rendelésed, szükségem van néhány elengedhetetlen
                        személyes adatodra. A jogalap attól függ, hogy hogyan kerültél velem kapcsolatba.
                        Vállalkozásomnál az alábbi adatkezelési tevékenységek merülnek fel a fenti domain(ek) kapcsán:
                    </p>
                </div>
                <div className='w-full flex flex-col align-baseline'>
                    <h1
                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        3.1 Egyszeri információkérés űrlapon vagy e-mail útján</h1>
                </div>
                <div className='w-full'>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Az adatkezelés jogalapja: Az információkérés során az adatkezelés az önkéntes hozzájárulásodon
                        alapul. A hozzájárulást bármikor visszavonhatod, a hozzájárulás visszavonásának az eredménye
                        azonban az lesz, hogy – ha korábban a feltett kérdést még nem válaszoltam meg – a válaszra már
                        nem fog sor kerülni.</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Az adatok forrása: Az adatokat te szolgáltatod részemre, amikor felveszed velem a kapcsolatot
                        akár a fenti domainek bármelyike alatt található kapcsolatfelvételi űrlapon, akár e-mailben. A
                        kérésed teljesítése során kizárólag akkor kérhetek az általad eleve szolgáltatott adatokhoz
                        képest többlet adatokat, ha a kért információ szolgáltatásához az a te pontosabb beazonosításod
                        szükséges..</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Érinettek köre: Egyszeri információkérés során akkor leszel Érintett, ha információt kérsz a
                        személyes adataid megadása mellett.</p>
                </div>
                <div className=''>
                    <div className='w-full flex flex-col align-baseline'>
                        <h1
                            className='mt-4 mb-0 text-lg font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                            A kezelt adatok köre:</h1>
                        <p
                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        </p>
                        <p
                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                            1. Az általad megadott név, amely adat kezelésének célja a te azonosításod annak érdekében,
                            hogy a megfelelő információt tudjuk nyújtani és veed a kapcsolatot tudjuk tartani.</p>
                        <p
                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                            2. Az általad megadott e-mail cím, amely adat kezelésének célja a veled történő
                            kapcsolattartás az információ nyújtása során.</p>
                        <p
                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                            3. Az általad feltett kérdés tartalma, amely adat kezelésének a célja a válaszadás.</p>
                        <p
                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                            4. Az általad feltett kérdés elküldésének időpontja, amely adat kezelésének célja a
                            válaszadás határidejének megállapítása.</p>
                        <p
                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                            5. Amennyiben az általad feltett kérdés üzleti titkot érint, úgy meg kell vizsgálnom, hogy
                            jogosult vagy-e a kért információhoz történő hozzáféréshez. Amennyiben az 1-3. alatti
                            adatokból nem derül ki ezen jogosultság egyértelműen, úgy az alábbi adatokat kérhetem el
                            abból a célból, hogy a válaszra való jogosultságot megállapítsam: születési név, születési
                            hely, idő, anyja neve. A többletadatok megadása szintén a te önkéntes hozzájárulásodon
                            alapul, annak elmaradása esetén azonban az adatszolgáltatás teljesítése során olyan tartalmú
                            nyilatkozatot bocsátok ki, amely szerint az adatszolgáltatás nem valósulhat meg, és amelyben
                            az adatszolgáltatás megtagadásának jogszabályi alapjait rögzítem.</p>
                        <p
                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                            Az adatkezelés időtartama: Ezeket az adatokat kizárólag a cél megvalósulásáig kezelem, azaz
                            azokat törlöm, amint a kérdésed a számodra megnyugtató módon, teljes körűen megválaszolásra
                            került, vagy amennyiben erre nem kerül sor, úgy akkor, amikor kibocsátom az
                            adatszolgáltatást megtagadó, jogszabály megjelölésével megfelelően alátámasztott
                            nyilatkozatot.
                        </p>
                        <p
                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                            Amennyiben a kérdésed nem egyszeri információkérés, hanem már magában hordozza annak a
                            lehetőségét, hogy a későbbiekben szerződést kötünk valamely szolgáltatásra, úgy a fenti
                            adatokat a szerződéskötésig, legfeljebb azonban 30 (harminc) napig őrzöm.
                        </p>
                        <p
                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                            Az adatkezelés helye: Az adatkezelés a info@webdesign-dd.hu e-mail címem webes felületén
                            történik. A weboldalon keresztül küldött megkeresés alábbi adatai tárolódnak továbbá a
                            weboldal saját belső adatbázisában azon domain név alatt, amelyről a megkeresést elküldted:
                            az üzenet szövege, a küldő e-mail címe és a küldés időpontja.
                        </p>
                        <p
                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                            Adatfeldolgozók: A weboldalak tárhelyszolgáltatója az CSB IT Hosting & Consulting Bt. 9985
                            Felsőszölnök, Hármashatár út 33. szám alatti székhelyű gazdasági társaság, aki
                            adatfeldolgozónak minősül, figyelemmel arra, hogy a tárhelyén érhetők el mind a részemre
                            vagy általam küldött elektronikus levelek, mind pedig az általad a webes felületről küldött
                            megkeresések.
                        </p>
                        <p
                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                            Az adatok megismerésére jogosultak: kizárólag az Adatkezelő. Amennyiben a tárhellyel
                            probléma merülne fel, úgy a hiba elhárítása érdekében az adatokba betekinthet a
                            tárhelyszolgáltató.
                        </p>
                        <div className='w-full flex flex-col align-baseline'>
                            <h1
                                className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                3.2 Egyszeri információkérés Facebook Messenger útján</h1>
                        </div>
                        <div className='w-full'>
                            <p
                                className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                Az adatkezelés jogalapja: Az információkérés során az adatkezelés önkéntes
                                hozzájáruláson alapul. A hozzájárulást bármikor visszavonhatod, a hozzájárulás
                                visszavonásának az eredménye azonban az lesz, hogy – ha korábban a feltett kérdést még
                                nem válaszoltam meg – a válaszra már nem fog sor kerülni.</p>
                            <p
                                className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                Az adatok forrása: Az adatokat te szolgáltatod részemre, amikor felveszed velem a
                                kapcsolatot a Facebook Messenger alkalmazással. A kérésed teljesítése során kizárólag
                                akkor kérhetek az általad eleve szolgáltatott adatokhoz képest többletadatokat, ha a
                                kért információ szolgáltatásához a te pontosabb beazonosításod szükséges.</p>
                            <p
                                className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                Érintettek köre: Egyszeri információkérés során akkor leszel Érintett, ha felveszed
                                velem a kapcsolatot Facebook Messenger útján.</p>
                        </div>
                        <div className=''>
                            <div className='w-full flex flex-col align-baseline'>
                                <h1
                                    className='mt-4 mb-0 text-lg font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    A kezelt adatok köre:</h1>
                                <p
                                    className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                </p>
                                <p
                                    className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    1. Az általad megadott név, amely adat kezelésének célja a te azonosításod annak
                                    érdekében, hogy a neked megfelelő információt tudjuk nyújtani és veled a kapcsolatot
                                    tudjuk tartani.</p>
                                <p
                                    className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    2. Az általad feltett kérdés tartalma, amely adat kezelésének a célja a válaszadás.
                                </p>
                                <p
                                    className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    3. Az általad feltett kérdés elküldésének időpontja, amely adat kezelésének célja a
                                    válaszadás határidejének megállapítása.</p>
                                <p
                                    className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Amennyiben Facebook Messengeren keresztül veszed fel velem a kapcsolatot, úgy a
                                    személyes profilodnak nyilvános adataiba betekintést nyerhetek. A személyes
                                    profillapodnak adatkezelője a Facebook, így ha ezzel kapcsolatosan adatkezelési
                                    kérdéseid merülnének fel, úgy kérlek, keresd fel a Facebook alábbi tájékoztató
                                    oldalát:</p>
                                <p
                                    className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    https://www.facebook.com/help/325807937506242/?ref=contextual</p>
                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Az adatkezelés időtartama: Ezeket az adatokat kizárólag a cél megvalósulásáig
                                    kezelem, azaz azokat törlöm, amint a kérdésed a számodra megnyugtató módon,
                                    teljeskörűen megválaszolásra került, vagy amennyiben erre nem kerül sor, úgy akkor,
                                    amikor kibocsátom az adatszolgáltatást megtagadó, jogszabály megjelölésével
                                    megfelelően alátámasztott nyilatkozatot.
                                </p>
                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Amennyiben a kérdésed nem egyszeri információkérés, hanem már magában hordozza annak
                                    a lehetőségét, hogy a későbbiekben szerződést kötünk valamely szolgáltatásra (akár
                                    oktatásra, akár egyéb tevékenységre), úgy a fenti adatokat a szerződéskötésig,
                                    legfeljebb azonban 30 (harminc) napig őrzöm.
                                </p>
                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    A Messenger-üzenetek általam történő törlése során a nálad látható üzenetfolyam nem
                                    változik, azt kizárólag te tudod törölni.
                                </p>
                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Az adatkezelés helye: Az adatkezelés a Facebook Messenger alkalmazásban történik.
                                </p>
                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Párhuzamos adatkezelés: A Messenger szolgáltatója a Facebook Ireland Ltd. (reg.nr.:
                                    462932, tel.: +353 1 5530588) 4 Grand Canal Square, Dublin, Ireland cím alatti
                                    székhelyű gazdasági társaság, aki adatkezelőnek minősül.
                                </p>
                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Az adatok megismerésére jogosultak: kizárólag az Adatkezelő. Amennyiben az
                                    üzenetváltással probléma merülne fel, úgy a hiba elhárítása érdekében az adatokba
                                    betekinthet a Facebook Ireland Ltd.
                                </p>
                            </div>
                        </div>
                        <div className='w-full flex flex-col align-baseline'>
                            <h1
                                className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                3.3 Szerződés megkötése</h1>
                        </div>
                        <div className='w-full'>
                            <p
                                className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                A szolgáltatás(ok) általad történő megrendelését követően az Általános Szerződési
                                Feltételek szerint közöttünk szerződés jön létre, amelynek megkötése egy önálló
                                adatkezelés.</p>
                        </div>
                        <div className=''>
                            <div className='w-full flex flex-col align-baseline'>
                                <h1
                                    className='mt-4 mb-0 text-lg font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Az adatkezelés jogalapja:</h1>
                                <p
                                    className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                </p>
                                <p
                                    className='my-2 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    amennyiben te vagy a szerződő fél, úgy az adatkezelés jogalapja a GDPR 6. cikkének
                                    (1) bekezdés b) pontjában foglaltan került meghatározásra, amely szerint az
                                    adatkezelés olyan szerződés teljesítéséhez szükséges, amelyben te vagy az egyik fél.
                                </p>
                                <p
                                    className='my-2 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    amennyiben te a szerződő vevőnk (megrendelőnk) képviseletében eljáró természetes
                                    személy vagy, úgy te a megrendelőnk képviseletét a Ptk. 3:21. §-ban foglaltakra
                                    figyelemmel, jogszabályi kötelezettséged teljesítése körében törvényes képviselőként
                                    látod el, amelynek alapján az adatkezelés jogalapja a GDPR 6. cikkének (1) bekezdés
                                    b) pontjában foglaltan került meghatározásra, amely szerint az adatkezelés olyan
                                    szerződés teljesítéséhez szükséges, amelyben te törvényesen képviseled a felet.
                                </p>
                                <p
                                    className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Az adatok forrása: Az adatokat szerződés előkészítése során te szolgáltatod, amikor
                                    az űrlapon kitöltöd a megfelelő mezőket.</p>
                                <p
                                    className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Érintettek köre: A szerződés megkötése során te akkor lesz Érintett, ha</p>
                                <p
                                    className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    - magánszemélyként vagy egyéni vállalkozóként szolgáltatásomat megrendelő
                                    természetes
                                    személy vagy,
                                </p>
                                <p
                                    className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    - jogi személy vevőm ügyvezetője vagy, vagy
                                </p>
                                <p
                                    className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    - jogi személy vevőm által kijelölt kapcsolattartó vagy.
                                </p>
                                <h1
                                    className='mt-4 mb-0 text-lg font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    A kezelt adatok köre:</h1>
                                <p
                                    className='my-4 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    név, amely adat kezelésének célja a te azonosításod szerződő partnerként
                                </p>
                                <p
                                    className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    - e-mail cím és telefonszám, amely adatok kezelésének célja a szerződés teljesítése
                                    érdekében történő kapcsolattartás.
                                </p>
                                <p
                                    className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    - számlázási adatok: számlázási név, cím, amely adatok kezelésének a célja a
                                    jövőbeni
                                    számla kiállítása.
                                </p>
                                <p
                                    className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    - megrendelt szolgáltatás megjelölése: az adatok kezelésének célja a szerződés
                                    teljesítése.
                                </p>

                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    A fentieken túl előfordulhat, hogy az általad megrendelt termék vagy szolgáltatás
                                    annyira egyedi, hogy a termék, vagy a szolgáltatás tárgya is azonosít téged, ebben
                                    az esetben a termék, vagy szolgáltatás megjelölését is személyes adatként kezeljük,
                                    amely adat kezelésének a célja a szerződés kereteinek meghatározása.</p>
                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Az adatkezelés időtartama: A fenti, a veled kötendő szerződésben rögzített adatokat
                                    a szerződés teljesítését követő 5 évig kezelem. Határozatlan időtartamú szerződés
                                    esetén a szerződés megszűnését követő 5 évig őrzöm adataidat. Az 5 év leteltét
                                    követően az elektronikusan nyilvántartott adataidat a rendszerből törlöm. Papír
                                    alapon a szerződéseket nem kezelem.</p>
                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Az adatkezelés helye: Az adatkezelés kizárólag elektronikusan történik. A szerződés
                                    megkötése elektronikusan történik, az űrlap általad történő kitöltésével és
                                    elküldésével. Az űrlap adatai a (hírlevélküldő rendszer neve) rendszer által
                                    biztosított tárhelyen tárolódnak.</p>
                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Az adatok megismerésére jogosultak: a szerződéskötés során kizárólag az Adatkezelő
                                    ismerheti meg az adataidat. Amennyiben a tárhellyel probléma merülne fel, úgy a hiba
                                    elhárításához szükséges mértékben az adatok megismerésére jogosult a (hírlevélküldő
                                    rendszer szolgáltató neve) adatfeldolgozó. Amennyiben a szerződés kapcsán hatósági
                                    eljárás merülne fel, úgy a megkötött szerződés adatait a hatósági ellenőrzés során
                                    eljáró hatóság, illetve annak képviselője ismerheti meg.</p>
                            </div>
                        </div>
                        <div className='w-full flex flex-col align-baseline'>
                            <h1
                                className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                3.4 Kapcsolattartás</h1>
                        </div>
                        <div className='w-full'>
                            <p
                                className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                A kapcsolattartásra a szerződés teljesítése érdekében van szükség.</p>
                        </div>
                        <div className=''>
                            <div className='w-full flex flex-col align-baseline'>
                                <h1
                                    className='mt-4 mb-0 text-lg font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Az adatkezelés jogalapja:</h1>
                                <p
                                    className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                </p>
                                <p
                                    className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    - amennyiben te vagy a szerződő fél, úgy az adatkezelés jogalapja a GDPR 6. cikkének
                                    (1) bekezdés b) pontjában foglaltan került meghatározásra, amely szerint az
                                    adatkezelés olyan szerződés teljesítéséhez szükséges, amelyben te vagy az egyik fél.
                                </p>
                                <p
                                    className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    - amennyiben te a szerződő vevőnk (megrendelőnk) képviseletében eljáró természetes
                                    személy vagy, úgy te a megrendelőnk képviseletét a Ptk. 3:21. §-ban foglaltakra
                                    figyelemmel, jogszabályi kötelezettséged teljesítése körében törvényes képviselőként
                                    látod el, amelynek alapján az adatkezelés jogalapja a GDPR 6. cikkének (1) bekezdés
                                    b) pontjában foglaltan került meghatározásra, amely szerint az adatkezelés olyan
                                    szerződés teljesítéséhez szükséges, amelyben te törvényesen képviseled a felet.
                                </p>
                                <p
                                    className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    - amennyiben te a szerződő beszállítónk által kapcsolattartásra kijelölt természetes
                                    személy vagy, úgy az adatkezelés jogalapja a Ptk. 6:272. §-ban foglalt megbízási
                                    szerződés teljesítése, illetve a te vevőnkkel (megrendelőnkkel) fennálló
                                    munkaszerződéséből, vagy egyéb polgári jogi szerződéséből eredő kötelezettséged.</p>
                                <p
                                    className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Az adatok forrása: A kapcsolattartási adataidat te, vagy a szerződést kötő személy
                                    közli a megrendelő űrlapon, illetve, ha változás következik be az adatokban, úgy
                                    külön egy erre irányuló tájékoztatással.</p>
                                <p
                                    className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Érintettek köre: Kapcsolattartás esetén te akkor leszel érintett, ha a szerződés
                                    kapcsán te kerültél kijelölésre kapcsolattartóként, vagy te vagy a szerződő fél, és
                                    nincs külön kijelölve kapcsolattartó.</p>
                                <h1
                                    className='mt-4 mb-0 text-lg font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    A kezelt adatok köre:</h1>
                                <p
                                    className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    - név, amely adat kezelésének célja a te azonosításod.
                                </p>
                                <p
                                    className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    - telefonszám, e-mail cím, amely adatok kezelésének célja a te elérhetőségednek
                                    biztosítása a szerződés fennállta során.
                                </p>
                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Az adatkezelés időtartama: A szerződés mindkét fél általi teljesítését követő nap.
                                </p>
                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Adatfeldolgozók: Adataid az űrlap benyújtását követően (hírlevélküldő rendszer
                                    szolgáltató neve, adatai) által biztosított online rendszerben tárolódnak.
                                </p>
                                <p
                                    className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                    Az adatok megismerésére jogosultak: a szerződéskötés során kizárólag az Adatkezelő
                                    ismerheti meg az adataidat. Amennyiben a tárhellyel probléma merülne fel, úgy a hiba
                                    elhárításához szükséges mértékben az adatok megismerésére jogosult a (hírlevélküldő
                                    rendszer szolgáltató neve) adatfeldolgozó. Amennyiben a szerződés kapcsán hatósági
                                    eljárás merülne fel, úgy a megkötött szerződés adatait – így a kapcsolattartás
                                    vonatkozásában megadott adatokat is – a hatósági ellenőrzés során eljáró hatóság,
                                    illetve annak képviselője ismerheti meg.
                                </p>
                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        3.5 Számlázás</h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Tevékenységemet a hatályos magyar és európai uniós jogszabályok keretei között
                                        végzem, erre figyelemmel a termékértékesítés kapcsán számlát állítok ki. Nem
                                        kerül sor számlázásra, amennyiben te ingyenes anyagot rendeltél tőlem.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Az adatkezelés jogalapja: az általános forgalmi adóról szóló 2007. évi CXXVII.
                                        törvény 159. §.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Az adatok forrása: az adatokat te szolgáltatod részemre ehhez a szolgáltatást
                                        megrendelő űrlap kitöltésével.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Érintettek köre: Számlázás során te akkor leszel Érintett, ha a számla a te
                                        nevedre, vagy olyan cég nevére szól, amely cég neve a te nevedet tartalmazza.
                                    </p>
                                </div>
                                <div className=''>
                                    <div className='w-full flex flex-col align-baseline'>
                                        <h1
                                            className='mt-4 mb-0 text-lg font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            A kezelt adatok köre:</h1>
                                        <p
                                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        </p>
                                        <p
                                            className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            - számlázási név,</p>
                                        <p
                                            className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            - számlázási cím,</p>
                                        <p
                                            className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            - megrendelt szolgáltatás megnevezése</p>
                                        <p
                                            className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            - megrendelt szolgáltatás díja</p>
                                        <p
                                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            amely adatok kezelésének célja egységesen a számla kiállítása.</p>
                                        <p
                                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Az adatkezelés időtartama: Az adatokat a Katv. 12. §-ában foglaltaknak
                                            megfelelő őrzési határidőig, azaz a számla esedékességétől számított 5 évig
                                            tárolom.
                                        </p>
                                        <p
                                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Adatfeldolgozók: Adataid az űrlap benyújtását követően a (hírlevélküldő
                                            rendszer szolgáltató neve, adatai) által biztosított on-line rendszerben
                                            tárolódnak, a számlázás az Octonull Kft. (Cg. 01-09-303201, adószám:
                                            13421739-2-41) KBOSS.hu Kft., 1031 Budapest, Záhony utca 7/D. szám alatti
                                            székhelyű gazdasági társaság Számlázz.hu (számlázó rendszer szolgáltató
                                            neve, adatai) rendszerével történik.
                                        </p>
                                        <p
                                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Az adatok megismerésére jogosultak: kizárólag az Adatkezelő. Amennyiben a
                                            számlázási rendszerekkel probléma merülne fel, úgy a hiba elhárításához
                                            szükséges mértékben az adatok megismerésére jogosult a (hírlevélküldő
                                            rendszer szolgáltató neve) és az (számlázó rendszer szolgáltató neve).
                                            adatfeldolgozó. Amennyiben a szerződés kapcsán hatósági eljárás merülne fel,
                                            úgy a kiállított számlák adatait a hatósági ellenőrzés során eljáró hatóság,
                                            illetve annak képviselője ismerheti meg.
                                        </p>
                                    </div>
                                </div>


                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        3.6 Panaszkezelés</h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Az adatkezelés jogalapja: a Ptk. 6:138. §-ban foglalt kötelezettségem
                                        teljesítése, amely jogszabályhely előírja, hogy a szerződésszegés esetén a
                                        sérelmet szenvedett fél jogosult a szolgáltatás teljesítésének követelésére.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Az adatok forrása: Panaszkezelés során az adatokat te szolgáltatod részemre,
                                        amikor a tevékenységemmel, vagy annak valamely részével szemben panaszt nyújtasz
                                        be hozzám.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Érintettek köre: Panaszkezelés során te akkor leszel Érintett, ha a panaszt te
                                        terjeszted elő. A panaszkezelés során az adatokat te szolgáltatod részünkre.</p>
                                </div>
                                <div className=''>
                                    <div className='w-full flex flex-col align-baseline'>
                                        <h1
                                            className='mt-4 mb-0 text-lg font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            A kezelt adatok köre:</h1>
                                        <p
                                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Az Adatkezelő az alábbi adatokat kezeli panaszkezelése során:</p>
                                        <p
                                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        </p>
                                        <p
                                            className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            - Név-célja az azonosítás</p>
                                        <p
                                            className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            - Panasz tartalma-A panasszal érintett tevékenység mindkét fél számára
                                            megnyugtató módon történő rendezése</p>
                                        <p
                                            className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            - Panaszbejelentés időpontja- A panasz nyilvántartásba vétele</p>
                                        <p
                                            className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            - Panasz megoldásának időpontja- A panasz lezárása a panasznyilvántartásban
                                        </p>
                                        <p
                                            className='my-1 ml-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            - Kapcsolódó szerződés- A panaszolt eset azonosítása</p>
                                        <p
                                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            A panaszkezelés során elképzelhető, hogy a fentieken túlmenően egyéb
                                            adatokat is kezelek, függően a panasz tartalmától, pl. amennyiben panaszod
                                            fizetési hiányosságok miatt került előterjesztésre, úgy a panaszkezelés
                                            során a szerződés teljesítése során korábban ismertté vált
                                            bankszámlaszámodat is felhasználhatom annak érdekében, hogy feltárjam a
                                            mulasztás okait.</p>
                                        <p
                                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Az adatkezelés időtartama: A panasz rendezését követő 5 év (a Ptk. által
                                            meghatározott általános elévülési idő).
                                        </p>
                                        <p
                                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Adatfeldolgozók: A panaszokat fogadó e-mail cím és a kapcsolatfelvételi
                                            űrlapot tartalmazó weboldalak tárhelyszolgáltatója az (tárhely szolgáltató
                                            neve, adatai) aki adatfeldolgozónak minősül, figyelemmel arra, hogy a
                                            tárhelyén érhetők el mind a részemre vagy általam küldött elektronikus
                                            levelek, mind pedig az általad a webes felületről küldött megkeresések.
                                        </p>
                                        <p
                                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Az adatok megismerésére jogosultak: kizárólag az Adatkezelő. Amennyiben a
                                            tárhellyel probléma merülne fel, úgy a hiba elhárítása érdekében az adatokba
                                            betekinthet a tárhelyszolgáltató.

                                            Panaszkezelés során az általam kezelt adatokat a fentieken túlmenően
                                            megismerheti a jogi képviselőm, illetve azon hatóság, amely – a panasz
                                            esetlegesen számodra nem megnyugtató módon történő rendezése esetén – a te
                                            megkeresésed alapján eljár.
                                        </p>
                                    </div>
                                </div>




                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        3.7 Weboldalak meglátogatása</h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Az adatkezelés jogalapja: Önkéntes hozzájárulás, amelyet te úgy gyakorolsz, hogy
                                        weboldalamat a böngésződben megnyitod.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Az adatok forrása: Az adatokat te szolgáltatod a weboldal meglátogatása során.
                                    </p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Érintettek köre: te abban az esetben leszel érintett, amennyiben weboldalamon
                                        látogatóként megjelensz.</p>
                                </div>
                                <div className=''>
                                    <div className='w-full flex flex-col align-baseline'>
                                        <p
                                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Az adatkezelés megvalósulása: Az adatkezelés cookie-kon keresztül történik.
                                            Az oldalon kizárólag a működéshez szükséges cookie-k találhatók, amelyek
                                            biztosítják neked a weboldalra történő belépés, illetve a megtekintendő
                                            tanfolyamok elérésének lehetőségét. Marketing célú cookie-kat nem használok.
                                        </p>
                                        <p
                                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        </p>
                                        <p
                                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Mi az a cookie?</p>
                                        <p
                                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            A cookie olyan pársoros kódrészlet, amelyet a böngésződ az egyes weboldalak
                                            meglátogatása során a számítógépeden elhelyez. Ezek tartalmazhatnak
                                            beazonosításhoz szükséges adatokat, érdeklődési körhöz kapcsolódó adatokat,
                                            azonban azok minden esetben a te számítógépeden tárolódnak. Ezeket a
                                            cookie-kat te a böngésződből törölheted a Beállítások menüpont alól.
                                        </p>
                                        <p
                                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            A weboldalaim kizárólag működéshez szükséges adatokat tartalmaznak, és azok
                                            élettartama többnyire a böngésző bezárásáig tart. Ez alól egyedüli kivétel a
                                            te Cookie-kra adott tájékoztatásodnak elfogadását rögzítő cookie, amelynek
                                            élettartama 1 év. Természetesen te ezt az 1 évet megelőzően is törölheted a
                                            számítógépedről a fenti módszerrel.
                                        </p>
                                    </div>
                                </div>



                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        3.8 Hírlevél</h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Az adatkezelés jogalapja: a te önkéntes hozzájárulásod.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Az adatok forrása: Az adatokat te szolgáltatod a hírlevélre történő feliratkozás
                                        során az űrlap kitöltésével.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Érintettek köre: te akkor leszel érintett, ha weboldalamon a
                                        hírlevél-szolgáltatásra feliratkozol.</p>
                                </div>
                                <div className=''>
                                    <div className='w-full flex flex-col align-baseline'>
                                        <h1
                                            className='mt-4 mb-0 text-lg font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            A kezelt adatok köre:</h1>
                                        <p
                                            className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            - név: amely adat kezelésének a célja a te azonosításod,</p>
                                        <p
                                            className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            - e-mail cím: amely adat kezelésének a célja a hírlevél küldése,</p>
                                        <p
                                            className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            - feliratkozás időpontja: amely adat kezelésének a célja annak
                                            megállapítása, hogy ténylegesen megtörtént a feliratkozás.</p>

                                        <p
                                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        </p>
                                        <p
                                            className='my-2 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Az adatkezelés időtartama: Leiratkozásig. A leiratkozással az adatkezeléshez
                                            adott hozzájárulásodat visszavonod. Leiratkozást követően a rendszerből az
                                            adataidat törlöm.</p>
                                        <p
                                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Adatfeldolgozók: Adataid az űrlap benyújtását követően a (hírlevélküldő
                                            rendszer szolgáltató neve, adatai) által biztosított online rendszerben
                                            tárolódnak.
                                        </p>
                                        <p
                                            className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Az adatok megismerésére jogosultak: a hírlevélküldés során kizárólag az
                                            Adatkezelő. Amennyiben a tárhellyel probléma merülne fel, úgy a hiba
                                            elhárításához szükséges mértékben az adatok megismerésére jogosult a
                                            (hírlevélküldő rendszer szolgáltató neve) adatfeldolgozó.
                                        </p>
                                    </div>
                                </div>

                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        4. Kezelem-e máshogy az adataidat?</h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Az adataidat kizárólag a fent meghatározott célokból kezelem, azokat harmadik
                                        fél részére – ha a jelen tájékoztatóban nem jeleztem külön – nem továbbítom.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Ez alól kivételt képez az az eset, ha nyomozó hatóság, bíróság, ügyészség,
                                        szabálysértési hatóság, közigazgatási hatóság, egyéb hatóság jogszabály
                                        felhatalmazása alapján keres meg. Ebben az esetben az adataidat kizárólag abban
                                        a körben és mértékben továbbítom, amelyre a hatóság pontos céllal és az adatok
                                        körének pontos megjelölésével megküldött megkeresése kiterjed, és amely a
                                        hatóság feladatainak ellátásához feltétlenül szükséges.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Amennyiben a szerződésből eredően köztem és közted, vagy az általad képviselt
                                        partner, vagy a téged kijelölő partner között jogvita alakul ki, úgy a szerződés
                                        a jogvita eldöntésére jogosult bíróság vagy mediátor részére.</p>
                                </div>

                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        5. Milyen jogok illetnek meg?</h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Megillet téged az adataid kezeléséről való tájékoztatás joga, amely
                                        tájékoztatást a jelen tájékoztató keretei között nyújtok.</p>
                                </div>

                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        5.1 Hozzáféréshez való jog</h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        E jog gyakorlása esetén kérelmezheted a rád vonatkozó személyes adatokhoz való
                                        hozzáférést. Ennek körében tájékoztatást kérhetsz tőlem arra vonatkozóan, hogy
                                        milyen adataidat, milyen jogalapon, milyen céllal, mennyi ideig kezelem, ezen
                                        adatokhoz, hogy jutottam hozzá, illetve ezen adatokat kinek, mikor, milyen
                                        céllal és milyen jogalappal továbbítottam, illetve, hogy kinek, mikor és milyen
                                        jogalappal biztosítottam hozzáférést.</p>
                                </div>


                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        5.2. Helyesbítéshez való jog</h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        E jog gyakorlása esetén kérelmezheted a fenti adatok helyesbítését,
                                        pontosítását, amennyiben azokban eltérést tapasztalsz. A fenti adatokat a
                                        kérésednek megfelelően 3 munkanapon belül köteles vagyok helyesbíteni.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Például: Ha értesítési címed, telefonszámod, e-mail címed megváltozott,
                                        kérheted, hogy az Adatkezelő az új adatot vezesse át a nyilvántartásában.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Adataidat – amennyiben azokban hiányosságot tapasztalsz – kiegészítheted. Az
                                        egyes adatkezelések során azonban a fenti adatokon túlmenően további adatokra
                                        nincs szükség, így azokat az Adatkezelő meghatározott cél nélkül kezelné.
                                        Amennyiben telefonszámból vagy e-mail címből újat kívánsz megadni és a régit nem
                                        szeretnéd töröltetni, úgy kérlek, jelöld meg, hogy a két elérhetőségi adat (a
                                        régi és az új) közül melyiket tekinted elsődlegesnek!</p>
                                </div>

                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        5.3. Törléshez, elfeledtetéshez való jog</h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        E jog gyakorlása esetén kérheted az adatkezelőtől mindazon adataidnak törlését,
                                        amelyeket az Adatkezelő a te hozzájárulásod alapján kezel, és hatályos
                                        jogszabály nem írja elő meghatározott időtartamig az őrzési kötelezettségét.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Kérheted továbbá azt is, hogy amennyiben az adatot az adatkezelő harmadik
                                        személy részére továbbította, és ezen adattovábbításra nem jogszabályi
                                        kötelezettség teljesítése érdekében került sor, úgy az adatkezelő a címzettnél
                                        az adatod törlését kezdeményezze.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Például kérheted az e-mail címednek vagy a telefonszámodnak a törlését a
                                        nyilvántartásunkból, azonban ez azt a következményt vonja maga után, hogy a
                                        megkötött szerződést a kapcsolattartás hiánya miatt nem tudjuk teljesíteni, erre
                                        figyelemmel a köztünk lévő szerződés neked felróható okból szűnik meg.</p>
                                </div>


                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        5.4. Adatkezelés korlátozásának joga (zároláshoz való jog)</h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Ha a személyes adat pontosságát vitatod, úgy arra az időtartamra korlátozhatod
                                        az adatkezelést, amíg az adatkezelő meg nem győződik az adat pontosságáról, és
                                        azt szükség esetén helyesbíti.

                                        Szintén kérheted az adataid kezelésének meghatározott cél(ok) szerinti
                                        korlátozását abban az esetben, ha az adatkezelés jogellenes, azonban mégsem
                                        kéred azok törlését. Az adataid ebben az esetben az általad megjelölt célra nem
                                        használhatók mindaddig, amíg ezzel ellentétes nyilatkozatot nem teszel.

                                        Például, ha szerződés kötése érdekében bocsátottad rendelkezésemre adataidat,
                                        azonban a szerződés mégsem jött létre, így az adatkezelés célja nem valósult
                                        meg, az adatkezelés jogellenessé válhat a szerződés aláírásának tervezett napja
                                        után. Azonban, ha úgy nyilatkozol, hogy adataidat nem kéred törölni, úgy az
                                        Adatkezelő nyilvántartásában azok továbbra is szerepelnek, így ha legközelebb
                                        szerződéskötési szándékkal keresel meg, úgy adataidat újra már nem kell
                                        felvennem, elegendő azok pontosítása.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Szintén kérheted az adataid kezelésének korlátozását abban az esetben, ha az
                                        adatkezelőnek már nincs szüksége a személyes adatokra adatkezelés céljából, de
                                        úgy döntesz, hogy azok tovább kezelését jogi igények előterjesztéséhez,
                                        érvényesítéséhez, védelméhez kéred. Ez esetben a korlátozó nyilatkozat megtétele
                                        mellett kérem, hogy az adatok további kezeléséhez történő hozzájárulást rögzítő
                                        nyilatkozatodat is tedd meg!

                                        Ha úgy érzed, hogy adataid kezelésével a jogos érdekeid sérülnek, úgy
                                        tiltakozásoddal korlátozhatod az adatkezelést arra az időtartamra, amíg
                                        megállapításra nem kerül, hogy az adataid kezelésének célja, illetve az adataid
                                        kezelésének jogos indokai elsőbbséget élveznek-e a te jogos érdekeiddel szemben.
                                    </p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        A korlátozás időtartama alatt a zárolt személyes adatokat az adatkezelő
                                        kizárólag tárolhatja, illetve a hozzájárulásoddal, vagy jogi igények
                                        előterjesztéséhez, érvényesítéséhez, védelméhez, vagy más természetes vagy jogi
                                        személy jogainak védelme érdekében, illetve fontos közérdekből lehet kezelni.

                                        Például: amennyiben az adatkezelőnek veled szemben közvetlen követelése
                                        keletkezne, úgy ennek hatósági, bírósági úton történő érvényesítéséhez adataidat
                                        felhasználhatja.</p>
                                </div>

                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        5.5. Tiltakozás joga</h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Jogosult vagy arra, hogy – hacsak jogszabály a korábban hozzájárulásod alapján
                                        megadott adataid tekintetében őrzési, kezelési kötelezettséget nem ír elő – a
                                        saját helyzeteddel kapcsolatos okokból bármikor tiltakozz személyes adataid
                                        kezelése ellen.</p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        A személyes adatokat közvetlen üzletszerzés érdekében az adatkezelő annyiban
                                        használja, amennyiben a hírlevelekben az új tanfolyamokról, szolgáltatásokról
                                        szóló tájékoztatások megjelennek. Az adatkezelő profilalkotást nem végez. Az
                                        adatkezelő tudományos vagy történelmi kutatási céllal, statisztikai céllal
                                        adatot nem kezel.
                                    </p>
                                </div>

                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        5.6. Hatósághoz fordulás joga</h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Az adatkezelő adatkezelésének kifogásolhatósága esetén jogosult vagy a Nemzeti
                                        Adatvédelmi és Információszabadság Hatósághoz fordulni. A Hatóság elérhetősége:
                                    </p>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Postacím: 1530 Budapest, Pf.: 5.

                                        Cím: 1125 Budapest, Szilágyi Erzsébet fasor 22/C.

                                        Telefon: +36 1 3911400

                                        E-mail: ugyfelszolgalat@naih.hu

                                        Web: http://naih.hu
                                    </p>
                                    </div>
                                    
                                    
                                <div className='w-full flex flex-col align-baseline'>
                                    <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        5.7. Bírósághoz fordulás joga </h1>
                                </div>
                                <div className='w-full'>
                                    <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Amennyiben úgy érzed, hogy adataid kezelésével megsértettük a jogaidat, és sérelmedet bírósági úton kívánod érvényesíteni, úgy megillet a bírósághoz fordulás joga. Ezen túlmenően az adatvédelmi hatóság határozata ellen a közigazgatási eljárás általános szabályai szerint jogorvoslattal élhetsz akként, hogy az illetékességgel és hatáskörrel bíró közigazgatási és munkaügyi bíróság előtt keresetet terjesztesz elő.
                                        </p>
                                        <h1
                                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Az adatkezelési alapelvek betartásáról szóló nyilatkozat</h1>
                                            <p
                                        className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                        Tekintettel arra, hogy vállalkozóként és magánszemélyként is rendkívül fontosnak tartom az adataid biztonságát, tájékoztatlak, hogy
                                        </p>

                                        <p className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'> -  adataid kezelését jogszerűen és tisztességesen, valamint a számodra átlátható módon végzem.</p>
                                        <p className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'> -  adataid gyűjtése csak meghatározott, egyértelmű és jogszerű célból történik, azokat nem kezelem e célokkal össze nem egyeztethető módon.</p>
                                        <p className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'> -  adataid a célok szempontjából megfelelőek és relevánsak, csak a szükséges mértékre korlátozódnak.</p>
                                        <p className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'> -  adataid az általad megadottak alapján pontosak és naprakészek, amennyiben erre igényed mutatkozik, úgy minden ésszerű intézkedést megteszek annak érdekében, hogy a személyes adataid törlésre vagy helyesbítésre kerüljenek.</p>
                                        <p className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'> -  adataidat olyan formában tárolom, amely az azonosításodat csak a személyes adatok kezelése céljainak eléréséhez szükséges ideig teszi lehetővé, azokat megfelelő technikai és szervezési intézkedésekkel védve őrzöm.</p>
                                        <p className='my-1 ml-6 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'> -  adataidat olyan módon kezelem, hogy biztosítva legyen a személyes adatok megfelelő biztonsága, az adatok jogosulatlan vagy jogellenes kezelésével, véletlen elvesztésével, megsemmisítésével vagy károsodásával szembeni védelmet is ide értve.</p>

                                        <h1
                        className='mt-7 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Dunasziget,2023.12.31</h1>
                                            <h1
                        className='mt-1 mb-0 text-xl font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                                            Dovigyel László</h1>
                                            <h1
                        className='mt-0 mb-0 text-lg font-base md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Adatkezelő</h1>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </Layout>
</>
)
}

export default PrivacyPolicy