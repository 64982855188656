import React from 'react';
import { useRef, useEffect } from 'react';
import AnimatedText from "../components/AnimatedText";
import Layout from '../components/Layout';
import profilePic from "../assets/developer-pic-1.png"
import { useMotionValue, useSpring, useInView } from "framer-motion";
import Services from "../components/Services"
import TransitionEffect from '../components/TransitionEffect';




const AnimatedNumbers = ({ value }) => {
const ref = useRef(null)
const motionValue = useMotionValue(0)
const springValue = useSpring(motionValue, { duration: 3000 })
const isInView = useInView( ref,{once:true} )

useEffect(() => {
if (isInView) {
motionValue.set(value)
}
}, [isInView, value, motionValue])

useEffect(() => {
springValue.on("change", (latest) => {
if (ref.current && latest.toFixed(0) <= value) { ref.current.textContent=latest.toFixed(0) } }) },[springValue,value])
    return <span ref={ref}></span>
    }
    const About = () => {
    return (
    <>
        <TransitionEffect />
        <main className='flex w-full flex-col items-center justify-center dark:text-light'>
            <Layout className='pt-16'>
                <div className='flex items-center justify-between w-full lg:flex-col '>
                    <div className='w-1/2 md:w-full'>
                        <img src={profilePic} alt="D&D webdesign"
                            className='w-full h-auto  md:inline-block md:w-full drop-shadow-[-20px_-7px_9px_rgba(0,0,0,0.3)] dark:drop-shadow-[-20px_-7px_9px_#0ef]' />
                    </div>
                    <div className='w-1/2 flex flex-col items-center self-center lg:w-full lg:text-center'>
                        <AnimatedText text="Kód és Kreativitás!"
                            className='!text-6xl !text-left xl:!text-5xl lg:!text-center lg:!text-6xl md:!text-5xl sm:!text-3xl  xs:!text-lg' />
                        <p className='my-4 text-base font-medium md:text-sm sm:text-xs'>Szia, én Dovigyel László vagyok, a D&D Design megálmodója és vezetője.A D&D Design
                            világában, ahol a kreatív víziók és az innovatív megoldások találkoznak. Büszkék vagyunk
                            arra, hogy ügyfeleink elképzeléseit valósággá változtatjuk, legyen szó egyedi weboldalakról,
                            lenyűgöző felhasználói felületekről vagy interaktív webes alkalmazásokról. Cégünk a legújabb
                            technológiákat és design trendeket alkalmazza, hogy a digitális térben is megállja a helyét.
                        </p>
                        <p className='my-4 text-base font-medium md:text-sm sm:text-xs'>Célunk, hogy minden
                            projektünkkel új szintre emeljük az online jelenlétet. Hiszünk abban, hogy a minőségi
                            kódolás és a figyelemfelkeltő design kéz a kézben jár a sikerhez vezető úton. Legyen szó
                            startupról vagy nagyvállalatról, a D&D Design csapata elkötelezett amellett, hogy a legjobb
                            megoldásokat nyújtsa, amelyek nem csak megfelelnek, hanem túlszárnyalják is várakozásait.
                        </p>
                        <p className='my-4 text-base font-medium md:text-sm sm:text-xs'>Válassza a D&D Design-t, és
                            engedje, hogy segítsünk Önnek abban, hogy elképzeléseit valósággá változtassuk a kód és a
                            design erejével. Kezdjük el együtt az utazást a digitális siker felé!</p>
                    </div>
                </div>
                <div className='grid grid-cols-8 gap-2 mt-20'>
                    <div
                        className='col-span-8 flex  items-center justify-between xl:col-span-8 xl:flex-row xl:items-center md:order-3 '>
                        <div className='flex flex-col items-end justify-center xl:items-center '>
                            <span className='inline-block text-7xl font-bold md:text-6xl sm:text-5xl xs:text-4xl'>
                                <AnimatedNumbers value={30} />+</span>
                            <h2
                                className='text-xl font-medium capitalize text-dark/75 dark:text-light/75 xl:text-center md:text-lg sm:text-base xs:text-sm'>
                                Satisfied clients</h2>
                        </div>
                        <div className='flex flex-col items-end justify-center xl:items-center'>
                            <span className='inline-block text-7xl font-bold md:text-6xl sm:text-5xl xs:text-4xl'>
                                <AnimatedNumbers value={30} />+</span>
                            <h2
                                className='text-xl font-medium capitalize text-dark/75 dark:text-light/75 xl:text-center md:text-lg sm:text-base xs:text-sm'>
                                complited projects</h2>
                        </div>
                        <div className='flex flex-col items-end justify-center xl:items-center'>
                            <span className='inline-block text-7xl font-bold md:text-6xl sm:text-5xl xs:text-4xl'>
                                <AnimatedNumbers value={5} />+</span>
                            <h2
                                className='text-xl font-medium capitalize text-dark/75 dark:text-light/75 xl:text-center md:text-lg sm:text-base xs:text-sm'>
                                years of experience</h2>
                        </div>
                    </div>
                    </div>
                    <Services/>
            </Layout>
        </main>
    </>
    )
    }

    export default About