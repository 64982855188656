import posthog from "posthog-js";
import { useState } from "react";
import { Link } from 'react-router-dom';
function CookieBanner() {

    const [showBanner, setShowBanner] = useState(true);

    const acceptCookies = () => {
        posthog.opt_in_capturing();
        setShowBanner(false);
    };

    const declineCookies = () => {
        posthog.opt_out_capturing();
        setShowBanner(false);
    }

    return (
        <div className="">
           {showBanner && (
  <div className="w-full h-40 z-10 flex flex-col items-center justify-center bg-dark dark:bg-white absolute bottom-0">
    <p className="text-white dark:text-dark">
    A weboldalon a minőségi felhasználói élmény érdekében sütiket használunk.
    <Link to="/cookie"
        className='underline underline-offset-2 ml-3 text-primary dark:text-primaryDark' target=''>Részletek</Link>
    </p>
    <div className="w-full mx-auto flex items-center justify-center ">
      <button
        onClick={acceptCookies}
        className="text-black mt-2 mx-2 hover:bg-green-700 border rounded-md bg-green-500"
      >
        Elfogad
      </button>
      <button
        onClick={declineCookies}
        className="text-black mt-2 mx-2 hover:bg-red-700 border rounded-md bg-red-500"
      >
        Blokk
      </button>
    </div>
  </div>
)}

        </div>

    )
}

export default CookieBanner;