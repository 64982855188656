import React from 'react'
import Layout from '../components/Layout';
import AnimatedText from "../components/AnimatedText";
import { Link } from 'react-router-dom';
const Cookie = () => {
return (
<>
    <Layout>
        <div className='w-full h-auto text-center'>
            <AnimatedText text="Cookie Tájékoztató"
                className='!text-6xl  xl:!text-5xl lg:!text-center lg:!text-6xl md:!text-5xl sm:!text-3xl !text-center xs:!text-base' />
            <div className='w-full flex flex-col items-center self-center lg:w-full lg:text-center'>
                <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                    Sütiket (cookie) használunk, hogy a legjobb felhasználói élményt biztosítsuk neked. Az alábbiakban
                    megismerheted, a sütik típusait, funkcióit. Az Elfogadom gomb megnyomásával és a weboldalon való
                    böngészés folytatásával hozzájárulsz a sütik használatához.</p>
                <div className='w-full flex flex-col align-baseline'>
                    <h1
                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Mi a cookie (süti)?</h1>
                </div>
                <div className='w-full'>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        A cookie-k kis adatfájlok, amelyeket a böngésző helyez el a felhasználó számítógépén vagy
                        eszközén. Többek között információt gyűjtenek, megjegyzik a látogató egyéni beállításait, és
                        általánosságban megkönnyítik a weboldal használatát a felhasználók számára. A sütik önmagukban
                        nem gyűjtik a számítógépen vagy a fájlokban tárolt adatokat. Olvasd el figyelmesen az alábbi
                        tájékoztatót további információkért, hogyan gyűjtjük az információt, amikor ezt a weboldalt
                        használod.
                    </p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Vannak feltétlenül szükséges cookiek, melyek segítséget nyújtanak honlapon történő mozgásban,
                        megjegyzik a felhasználó egyes oldalakon végzett műveleteit. Nélkülük a szolgáltatások nem
                        működnének. Ezek a cookie-k nem tárolnak olyan információkat a felhasználóról, hogy pl. milyen
                        oldalakat nézett meg az interneten, és olyanokat sem, amelyeket marketing célból lehetne
                        felhasználni.
                    </p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Vannak teljesítményt biztosító cookie-k, melyek információkat gyűjtenek arról, hogy a
                        felhasználó hogyan használja a weboldalunkat (pl. milyen oldalakat tekint meg, illetve
                        tapasztal-e valamilyen hibát). Ezek a cookie-k nem gyűjtenek olyan adatokat, amelyek alapján
                        azonosítható a felhasználó. Csak olyan célra használjuk, hogy javítani tudjuk a honlap
                        működését, megértsük a vevőink érdeklődését, és mérjük, milyen hatékonyak a hirdetéseink.
                    </p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Vannak használatot elősegítő cookie-k, melyeket különböző szolgáltatások nyújtására használunk,
                        illetve arra, hogy megjegyezzük a felhasználó beállításait, hogy megkönnyítsük az oldal
                        látogatását.
                    </p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        A weboldal használata során a feltétlenül szükséges, a teljesítményt biztosító, és a használatot
                        elősegítő cookie-k elfogadása szükséges honlapunk megfelelő használatához. Ha kikapcsolod ezeket
                        a cookie-kat, nem garantált a weboldal megfelelő működése, illetve a Te megfelelő biztonságod az
                        oldalunk használata során.
                    </p>
                    <h1
                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        A cookie-k elfogadásával a következőkhöz járulsz hozzá:</h1>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        A weboldal böngészésével elfogadod, hogy cookie-kat helyezzünk el a számítógépeden a honlap
                        használatának elemzése céljából. Ha ezen az oldalon böngészel, és letiltod a cookie-kat,
                        előfordulhat, hogy weboldalunk nem fog teljeskörűen működni.
                    </p>
                    <h1
                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Hogyan lehet letiltani a cookie-kat?</h1>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Minden böngészőben van mód a cookie beállítások változtatására.

                        Legtöbbször a böngészőkben a „Beállítások” menüben találhatóak a cookie-kal kapcsolatos
                        beállítások is. Az alábbi linkek segítségedre lesznek ezekben a beállításokban:

                        A leggyakoribb böngészők cookie beállításairól az alábbi linkeken tájékozódhatsz:
                    </p>
                    <div className='w-full text-left md:text-center flex flex-col'>
                        <Link to="https://support.mozilla.org/hu/kb/weboldalak-altal-elhelyezett-sutik-torlese-szamito"
                            className='text-xl md:text-base underline-offset-2  text-primary dark:text-primaryDark'
                            target='_blank'>Firefox</Link>
                        <Link to="https://support.google.com/accounts/answer/61416?hl=hu"
                            className='text-xl md:text-base underline-offset-2  text-primary dark:text-primaryDark'
                            target='_blank'>Google Chrome</Link>
                        <Link
                            to="https://support.microsoft.com/hu-hu/windows/cookie-k-t%C3%B6rl%C3%A9se-%C3%A9s-kezel%C3%A9se-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11"
                            className='text-xl md:text-base underline-offset-2  text-primary dark:text-primaryDark'
                            target='_blank'>Internet Explorer 11</Link>
                        <Link
                            to="https://support.microsoft.com/hu-hu/windows/cookie-k-t%C3%B6rl%C3%A9se-%C3%A9s-kezel%C3%A9se-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-10"
                            className='text-xl md:text-base underline-offset-2  text-primary dark:text-primaryDark'
                                target='_blank'>Internet Explorer 10</Link>
                                                    <Link
                            to="https://support.microsoft.com/hu-hu/windows/cookie-k-t%C3%B6rl%C3%A9se-%C3%A9s-kezel%C3%A9se-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-9"
                            className='text-xl md:text-base underline-offset-2  text-primary dark:text-primaryDark'
                                target='_blank'>Internet Explorer 9</Link>
                                                                                <Link
                            to="https://support.microsoft.com/hu-hu/windows/cookie-k-t%C3%B6rl%C3%A9se-%C3%A9s-kezel%C3%A9se-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-8"
                            className='text-xl md:text-base underline-offset-2  text-primary dark:text-primaryDark'
                                target='_blank'>Internet Explorer 8</Link>
                                                                                                            <Link
                            to="https://support.apple.com/hu-hu/guide/safari/sfri47acf5d6/mac"
                            className='text-xl md:text-base underline-offset-2  text-primary dark:text-primaryDark'
                            target='_blank'>Safari</Link>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</>
)
}

export default Cookie