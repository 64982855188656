import React from 'react'
import Layout from '../components/Layout';
import AnimatedText from "../components/AnimatedText";

const Impressum = () => {
return (
<>
    <Layout>
        <div className='w-full h-auto text-center'>
            <AnimatedText text="Impressum"
                className='!text-6xl  xl:!text-5xl lg:!text-center lg:!text-6xl md:!text-5xl sm:!text-3xl !text-center xs:!text-base' />
            <div className='w-full flex flex-col items-center self-center lg:w-full lg:text-center'>
                <div className='w-full flex flex-col align-baseline'>
                    <h1
                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Üzemeltető adatai:</h1>
                </div>
                <div className='w-full'>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Név:Dovigyel László e.v.</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Székhely:9226 Dunasziget Sérfenyő utca 50.</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Adószám:</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Nyilvántartási szám:</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Email-cím:info@webdesign-dd.hu
                    </p>
                </div>
                <div className='w-full flex flex-col align-baseline'>
                    <h1
                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Tárhelyszolgáltató adatai:</h1>
                </div>
                <div className='w-full'>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Név:CSB IT Hosting & Consulting Bt.</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Székhely: 9985 Felsőszölnök, Hármashatár út 33.</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Adószám:27834866-2-18</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Honlap: www.awh.hu www.csb-it.hu</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Email-cím: info@awh.hu</p>
                </div>
                <div className='w-full flex flex-col align-baseline'>
                    <h1
                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                       Szakmai érdek-képviseleti szerv (kamara) adatai:</h1>
                </div>
                <div className='w-full'>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                    Név: Győr-Moson-Sopron Megyei Kereskedelmi és Iparkamara</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                    Cím: 9021 Győr Szent István u. 10/a.</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                    Levelezési cím:  9001. Pf. 673.</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                    Tel: +36 96/520-200,</p>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                    E-mail: kamara@gymskik.hu</p>
                    </div>
                    <div className='w-full flex flex-col align-baseline'>
                    <h1
                        className='mt-4 mb-0 text-xl font-bold md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                        Jogi nyilatkozat</h1>
                    </div>
                    <div className='w-full'>
                    <p className='my-4 text-base font-medium md:text-sm sm:text-xs text-dark dark:text-light text-left'>
                    Jelen weboldalon alkalmazott weboldal ötletek és tervek szerzői jogai Dovigyel Lászlót illetik, amelyeket szabadúszóként, megbízások alapján készített. A weboldalon található tartalmak terjesztése, továbbítása, közzététele, vagy más weboldalon történő felhasználása kizárólag a tulajdonos előzetes írásbeli hozzájárulását követően, kizárólag forrás megjelöléssel lehetséges! Jelen weboldal designja a szerzői jogvédelem szerint képzőművészeti alkotásnak minősül. A honlap designjának, mint számítógépes grafikával létrehozott képzőművészeti alkotásnak a szerzője Dovigyel László. Az oldal grafikai és technikai szerkezete, funkcióinak megvalósítási módja, forráskódja szerzői jogvédelem alatt áll. A jogtulajdonos írásos engedélye nélkül az oldal forráskódjának és egyéb részeinek másolása, módosítása, további felhasználása szigorúan tilos! A bejegyzések, fotók, weboldaltervek üzleti célú, kereskedelmi felhasználása szigorúan tilos! Az oldalon közzétett ismertetők, adatok, szolgáltatások tájékoztató jellegűek, az esetleges pontatlanságokért a szerző felelősséget nem vállal.</p>
                        </div>
            </div>
        </div>
    </Layout>
</>
)
}

export default Impressum