
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import About from "./pages/About.jsx";
import Projects from "./pages/Projects.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import Cookie from "./pages/Cookie.jsx";
import Impressum from "./pages/Impressum.jsx";
import Contact from "./pages/Contact.jsx";

import { HashRouter, Routes, Route } from "react-router-dom";
import CookieBanner from "./components/CookieBanner";
import posthog from "posthog-js";
import "./App.css";

function App() {
  return (

    <HashRouter>
      <Navbar />
      {posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing()
        ? null
        : <CookieBanner />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gdpr" element={<PrivacyPolicy />} />
        <Route path="/cookie" element={<Cookie />} />
        <Route path="/impressum" element={<Impressum />} />
      </Routes>
      <Footer />
    </HashRouter>
  );
}

export default App;
