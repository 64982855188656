import React from 'react'
import {Link } from 'react-router-dom'
import { motion } from "framer-motion"
import logo from "../assets/logo.png"
const MotionLink=motion(Link)
const Logo = () => {
  return (
      <div className='flex items-center justify-center mt-4'>
          <MotionLink to='/' className="flex cursor-pointer hover:scale-125 transition-all duration-300 ease-in-out"
          ><img src={logo} alt="D&D Logo" className='w-10' /><span className='mt-3'>design</span></MotionLink>
    </div>
  )
}

export default Logo