import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Layout from '../components/Layout';
import AnimatedText from '../components/AnimatedText';

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sendStatus, setSendStatus] = useState(""); // Új állapot a küldés állapotának tárolására
  const form = useRef();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "name") setName(value);
    else if (name === "email") setEmail(value);
    else if (name === "message") setMessage(value);
  };

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs.sendForm('service_cgq2gmz', 'template_yldd5wn', form.current, 'jHhGPUC4hI82oAEPj')
      .then((result) => {
        console.log(result.text);
        setName("");
        setEmail("");
        setMessage("");
        setSendStatus("success"); // Sikeres küldés állapot beállítása
        form.current.reset();
      }, (error) => {
        console.log(error.text);
        setSendStatus("fail"); // Sikertelen küldés állapot beállítása
      });
  };
  
  return (
    <>
      <Layout>
        <div className='w-full h-auto text-center'>
        <AnimatedText  text="Lépj kapcsolatba velem"
            className='!text-6xl  xl:!text-5xl lg:!text-center lg:!text-6xl md:!text-5xl sm:!text-3xl !text-center xs:!text-base'/>
        </div>
        <div className="flex flex-col items-center justify-center p-12 bg-light dark:bg-dark xs:!p-0">
        <div className="mx-auto w-full max-w-[550px]">
          <form ref={form} onSubmit={sendEmail}>
            <div className="mb-5">
              <label htmlFor="name" className="mb-3 block text-base font-medium text-[#07074D] dark:text-light">
                Név
              </label>
              <input type="text" name="name" id="name" placeholder="Teljes név" required value={name} onChange={handleChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
            </div>
            <div className="mb-5">
              <label htmlFor="email" className="mb-3 block text-base font-medium text-[#07074D] dark:text-light">
                Email cím
              </label>
              <input type="email" name="email" id="email" placeholder="example@domain.com" required value={email} onChange={handleChange}
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
            </div>
            <div className="mb-5">
              <label htmlFor="message" className="mb-3 block text-base font-medium text-[#07074D] dark:text-light">
                Üzenet
              </label>
              <textarea rows="4" name="message" id="message" placeholder="Üzenet" required value={message} onChange={handleChange}
                className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"></textarea>
            </div>
            <div className='flex items-center'>
              <button type="submit" className="rounded-md bg-primaryDark dark:bg-primaryDark py-3 px-8 text-base font-semibold text-white outline-none
                  hover:shadow-xl hover:shadow-white hover:text-dark transition-all duration-300 ease-in-out">
                Küldés
              </button>
              {sendStatus === "success" && <span className="ml-3 text-green-500">Elküldve</span>}
              {sendStatus === "fail" && <span className="ml-3 text-red-500">Küldés sikertelen</span>}
            </div>
          </form>
        </div>
      </div>
      </Layout>
    </>
  );
}

export default Contact;
