import React from 'react'
import Layout from '../components/Layout'
import AnimatedText from '../components/AnimatedText'
import TransitionEffect from '../components/TransitionEffect';
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import hopiheKlima from "../assets/hopiheKlima.png";
import barberShop from "../assets/barberShop.png";
import restaurant from "../assets/restaurant.png";
import hairDresser from "../assets/hairDresser.png";
import restaurantF from "../assets/restaurantF.png";
import carWash from "../assets/carWash.png";
import beautyStudio from "../assets/beautyStudio.png";
import hotel from "../assets/hotel.png";
import bakery from "../assets/The-Bakery.png"
const FeaturedProject = ({ type, title, summary, img, link,style }) => {
    return (
        <article className={`relative w-full flex flex-col items-center justify-between rounded-3xl border border-solid border-dark bg-light shadow-2xl p-10 dark:bg-dark
        dark:border-light sm:p-8 xs:rounded-2xl xs:rounded-br-3xl xs:p-4 ${style}`}>
            <Link to={link} target='_blank' className='w-full cursor-pointer overflow-hidden rounded-lg mb-6'>
                <motion.img src={img} alt={title} className='w-full h-auto' whileHover={{ scale: 1.05 }} transition={{ duration: 0.2 }} />
            </Link>
            <div className='flex flex-col items-start justify-between w-full'>
                <span className='text-primary font-medium text-xl dark:text-primaryDark xs:text-base'>{type}</span>
                <Link to={link} target='_blank' className='hover:underline underline-offset-2 '>
                    <h2 className='my-2 text-left text-4xl font-bold dark:text-light sm:text-3xl xs:text-base'>{title}</h2>
                </Link>
                <p className='my-2 font-medium text-dark dark:text-light sm:text-base xs:hidden'>{summary}</p>
                <div className='mt-2 flex items-center'>
                    <Link to={link} target='_blank' className='rounded-lg bg-dark text-light p-2 px-6 text-lg font-semibold dark:bg-light dark:text-dark sm:px-4 sm:text-base'>Megnézem</Link>
                </div>
            </div>
        </article>
    )
}

const Project = ({ title, type, img, link,style}) => {
    return (
        <article className={`w-full flex flex-col items-center justify-center rounded-2xl border border-solid border-dark shadow-lg bg-light relative dark:bg-dark dark:border-light p-4 sm:p-6 md:p-8 ${style}`}>
            <Link to={link} target='_blank' className='w-full cursor-pointer overflow-hidden rounded-lg'>
                <motion.img src={img} alt={title} className='w-full h-auto transition duration-200' whileHover={{ scale: 1.05 }} />
            </Link>
            <div className='w-full flex flex-col items-start justify-between mt-4 px-2'>
                <span className='text-primary dark:text-primaryDark font-medium text-xl lg:text-lg md:text-base xs:hidden'>{type}</span>
                <Link to={link} target='_blank' className='hover:underline underline-offset-2'>
                    <h2 className='my-2 w-full text-left text-3xl font-bold lg:text-2xl md:text-xl xs:text-base'>{title}</h2>
                </Link>
                <div className='w-full flex items-center justify-between'>
                    <Link to={link} target='_blank' className='underline text-lg font-semibold md:text-base'>Megnézem</Link>
                </div>
            </div>
        </article>
    )
}

const Projects = () => {
    return (
      <>
        <TransitionEffect />
        <main className='w-full min-h-screen flex flex-col items-center justify-center dark:text-light'>
          <Layout className='pt-16'>
                    <AnimatedText text={'Alkotunk. Inspiráció. Innováció.'} className='mb-16 xs:text-2xl sm:text-5xl md:text-6xl lg:text-7xl' />
            <div className='bg-red w-full h-auto grid grid-cols-12 gap-7 xs:gap-4'>
            <div className='col-span-12 '>
                      <FeaturedProject
                              title={'The Bakery pékség'}
                              summary={`Az pékség weboldala a reszponzív kialakítás és a modern stílus tökéletes ötvözete, amely felhasználóbarát navigációt biztosít minden eszközön.`}
                              link={'https://bakery.webdesign-dd.hu/'}
                              img={bakery}
                                type={'Responsive weboldal'}
                                // eslint-disable-next-line react/style-prop-object
                                style={"shadow-yellow-400"}
                          />
                        </div>
                    <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'The Barbershop '}
                              link={'https://barber.webdesign-dd.hu/'}
                              img={barberShop}
                                type={'Responsive bemutatkozó oldal'}
                                // eslint-disable-next-line react/style-prop-object
                                style={"shadow-orange-500"}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'Hópihe-Klíma '}
                              link={'https://www.hopihe-klima.hu/'}
                              img={hopiheKlima}
                                type={'Responsive bemutatkozó oldal'}
                                // eslint-disable-next-line react/style-prop-object
                                style={"shadow-blue-500"}
                          />
                      </div>
                        <div className='col-span-12 '>
                      <FeaturedProject
                              title={'LA FRAGUA étterem'}
                              summary={`Az étterem weboldala a reszponzív kialakítás és a modern stílus tökéletes ötvözete, amely felhasználóbarát navigációt biztosít minden eszközön.`}
                              link={'https://restaurant.webdesign-dd.hu/'}
                              img={restaurant}
                                type={'Responsive weboldal'}
                                // eslint-disable-next-line react/style-prop-object
                                style={"shadow-yellow-400"}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'The Hair Dresser'}
                              link={'https://hairdresser.webdesign-dd.hu/'}
                              img={hairDresser}
                                type={'Responsive bemutatkozó oldal'}
                                // eslint-disable-next-line react/style-prop-object
                                style={"shadow-brown-200"}
                          />
                      </div>
                      <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'Beauty Studio'}
                              link={'https://beauty.webdesign-dd.hu/'}
                              img={beautyStudio}
                                type={'Responsive bemutatkozó oldal'}
                                // eslint-disable-next-line react/style-prop-object
                                style={"shadow-red-200"}
                          />
                        </div>
                        <div className='col-span-12 '>
                      <FeaturedProject
                              title={'Farriers étterem '}
                              summary={`Az étterem weboldala a reszponzív kialakítás és a modern stílus tökéletes ötvözete, amely felhasználóbarát navigációt biztosít minden eszközön.`}
                              link={'https://restaurant2.dovigyel.hu/'}
                              img={restaurantF}
                                type={'Responsive weboldal'}
                                // eslint-disable-next-line react/style-prop-object
                                style={"shadow-orange-300"}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-12 '>
                      <Project
                              title={'Car Wash '}
                              link={'https://carwash.webdesign-dd.hu/'}
                              img={carWash}
                                type={'Responsive bemutatkozó oldal'}
                                // eslint-disable-next-line react/style-prop-object
                                style={"shadow-blue-400"}
                          />
                      </div>
                      <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'The Hotel '}
                              link={'https://hotel.webdesign-dd.hu/'}
                              img={hotel}
                                type={'Responsive bemutatkozó oldal'}
                                // eslint-disable-next-line react/style-prop-object
                                style={"shadow-brown-600"}
                          />
                      </div>
                    </div>
          </Layout>
        </main>
      </>
    )
  }
  
  

export default Projects

